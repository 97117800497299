import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Nizams from '../images/DP.png';




const FloatingWhatsapp = () => {
  return (
    <div>
        <FloatingWhatsApp phoneNumber='+44 7404649025' accountName='The Nizams' avatar={Nizams} />
    </div>
  )
}

export default FloatingWhatsapp